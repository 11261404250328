// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: listStylesStructureWide or buttonStylesDarkThemePrimary

export const listStylesDefaultTheme = [
	{
		name: 'Header background',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Header text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Row background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Row background (alternative)',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'Row background (selected)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
];

export const listCheckboxDefaultTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Container (selected)',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected)',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Container (selected): hover',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): hover',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'border-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: 'Container (selected): focus',
		property: 'background-color',
		value: '923100',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): focus',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
]

export const listStylesDarkTheme = [
	{
		name: 'Header background',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Header text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Row background',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Row background (alternative)',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Row background (selected)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
];

export const listCheckboxDarkTheme = [
	{
		name: 'Container',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Container (selected)',
		property: 'background-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected)',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Container: hover',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container (selected): hover',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): hover',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Container: focus',
		property: 'border-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Container (selected): focus',
		property: 'background-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: 'Checkmark (selected): focus',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
]

export const listStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'List header text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: 'Item count text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'bold / 700',
		isColorTile: false,
	},
	{
		name: 'Instructional text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: 'Menu text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const listStylesStructure = [
	{
		name: 'List header',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'List row',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Text',
		property: 'vertical-align',
		value: 'top',
		isColorTile: false,
	},
	{
		name: 'Checkbox',
		property: 'width',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'vertical-align',
		value: 'top',
		isColorTile: false,
	},
	{
		name: 'Actions icon',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Pagination',
		property: 'min-height',
		value: '52px',
		isColorTile: false,
	},
];
