<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'List'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body" label="live example">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="single">Single select</option>
					<option value="multi">Multi-select</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
				<div class="page-live-example-controls">
					<button style="margin-right: 8px;" class="button" (click)="showPagination = !showPagination">{{ showPagination ? 'Hide' : 'Show' }} pagination</button>
					<button class="button" (click)="showInstructionalText = !showInstructionalText">{{ showInstructionalText ? 'Hide' : 'Show' }} instructional text</button>
				</div>
			</div>
			<div class="page-live-example-content">
				<!-------------------------------------------------------------------->
				<kendo-grid
                    [class.page-live-show]="currLive === 'single'"
                    [kendoGridBinding]="data"
                    [sortable]="false"
                    [filterable]="false"
                    [pageable]="showPagination && {type: 'input', pageSizes: [10, 25, 50, 100, 200]}"
                    [pageSize]="showPagination ? 25 : null"
                    [height]="500"
                    [resizable]="false"
                    [selectable]="singleSelectableSettings"
                    [rowSelected]="iscurrentRowSelected"
                    [rowClass]="rowSelectedClass">
                    <kendo-grid-checkbox-column class="sh-list-checkbox-column">
                        <ng-template kendoGridHeaderTemplate let-column>
                          <div class="sh-item-count-container">
                            <strong class="sh-item-count">{{ data.length }} items</strong>
                          </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="k-checkbox-wrap">
                            <input
                            #checkboxList
                            type="checkbox"
                            kendoCheckBox
                            [checked]="dataItem.email === selectedRowIdentity"
                            (change)="onCheckboxChange(dataItem)"
                            [attr.aria-label]="dataItem.name + ' ' + dataItem.email"
                            />
                            <kendo-label [for]="checkboxList" [text]="dataItem.name"></kendo-label>
                          </div>
                          <kendo-formhint *ngIf="showInstructionalText">{{dataItem.email}}</kendo-formhint>
                        </ng-template>
                    </kendo-grid-checkbox-column>
                    <kendo-grid-column [width]="54">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-menu [openOnClick]="{toggle: 'leave'}" (open)="onMenuOpen(dataItem, 'single')">
                                <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
                                    <kendo-button class="sh-list-action-button" themeColor="primary" fillMode="flat" [kendoMenuItemLink]="index" [svgIcon]="item.svgIcon">
                                        {{ item.text }}
                                    </kendo-button>
                                </ng-template>
                                <kendo-menu-item [svgIcon]="dotsIcon">
                                    <kendo-menu-item text="Edit User" [svgIcon]="editIcon"></kendo-menu-item>
                                    <kendo-menu-item text="Delete" [svgIcon]="xIcon"></kendo-menu-item>
                                </kendo-menu-item>
                            </kendo-menu>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
				<!-------------------------------------------------------------------->

                <kendo-grid
                    [class.page-live-show]="currLive === 'multi'"
                    [kendoGridBinding]="data"
                    [sortable]="false"
                    [filterable]="false"
                    [pageable]="showPagination && {type: 'input', pageSizes: [10, 25, 50, 100, 200]}"
                    [pageSize]="showPagination ? 25 : null"
                    [height]="500"
                    [resizable]="false"
                    [selectable]="multiSelectableSettings"
                    [rowSelected]="isRowSelected">
                    <kendo-grid-checkbox-column class="sh-list-checkbox-column">
                        <ng-template kendoGridHeaderTemplate let-column>
                            <input type="checkbox"
                                kendoCheckBox
                                kendoGridSelectAllCheckbox
                                [state]="selectAllState"
                                (selectAllChange)="onSelectAllChange($event)"/>
                            <div class="sh-item-count-container">
                                <strong class="sh-item-count">{{ data.length }} items</strong>
                                ({{ checkedLength }} selected |
                                <a class="sh-item-count-selection"
                                    tabindex="0"
                                    aria-disabled="false"
                                    aria-haspopup="false"
                                    type="button"
                                    (click)="onSelectAllChange(selectAllState === 'checked' ? 'unchecked' : 'checked')">
                                    {{  selectAllState === 'checked' ? 'Deselect All' : 'Select all ' + data.length + ' rows' }}
                                </a>)
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="k-checkbox-wrap">
                            <input
                            #checkboxList
                            type="checkbox"
                            kendoCheckBox
                            (change)="selectionChange(dataItem)"
                            [checked]="this.multiSelectItems[dataItem.name]"
                            [attr.aria-label]="dataItem.name + ' ' + dataItem.email"
                            />
                            <kendo-label [for]="checkboxList" [text]="dataItem.name"></kendo-label>
                          </div>
                          <kendo-formhint *ngIf="showInstructionalText">{{dataItem.email}}</kendo-formhint>
                        </ng-template>
                    </kendo-grid-checkbox-column>
                    <kendo-grid-column [width]="48">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-menu [openOnClick]="{toggle: 'leave'}" (open)="onMenuOpen(dataItem, 'multi')">
                                <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index" >
                                    <kendo-button class="sh-list-action-button" themeColor="primary" fillMode="flat" [kendoMenuItemLink]="index" [svgIcon]="item.svgIcon">
                                        {{ item.text }}
                                    </kendo-button>
                                </ng-template>
                                <kendo-menu-item [svgIcon]="dotsIcon" [disabled]="disableMenus">
                                    <kendo-menu-item text="Edit User" [svgIcon]="editIcon"></kendo-menu-item>
                                    <kendo-menu-item text="Delete" [svgIcon]="xIcon"></kendo-menu-item>
                                </kendo-menu-item>
                            </kendo-menu>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard" (click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark" [hidden]="currLive !== 'single'">
				{{ singleSelectListHTML }}
			</pre>

			<pre prism class="dark" [hidden]="currLive !== 'multi'">
				{{ multiSelectListHTML }}
			</pre>
		</section>
		<section class="page-sub-section" id="dev-api">
			<h3 id="subSectionTitleDevApi" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-api')">
				API <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<a class="page-kendo-link" target="_blank" href="https://www.telerik.com/kendo-angular-ui/components/grid/api/">
						<svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18" viewBox="0 0 49 60.3">
							<path fill="#5ce500" d="M11.2 14.9L0 21.3l17.4 10.1v20.1l11.2-6.4c.5-.3.9-1 .9-1.6V24.4L13 14.9c-.5-.3-1.3-.3-1.8 0z"></path>
							<path fill="#5ce500" d="M12.1 48.4V34.5L0 41.5zM25 .2c-.5-.3-1.3-.3-1.8 0L10.7 7.4l24.1 13.9v27.9L47.3 42c.5-.3.9-1 .9-1.6V13.6L25 .2z"></path>
						</svg> Kendo Grid API
					</a>
				</li>
				<li class="page-section-list-item">
					Refer to the <a href="components/list#behaviors">behaviors section</a> to see supported component features.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultIcon" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Pagination</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultPagination" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Menu</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultMenu" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Checkbox</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkCheckbox" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkIcon" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Pagination</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkPagination" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Menu</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkMenu" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-select">
			<h3 id="subSectionTitleBehaviorsSelect" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-select')">
				Select <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Lists can be single-select or multi-select. Users can select or deselect all list
					items by using the text buttons <b>Select all rows</b> and  <b>Deselect all rows</b> or the checkbox
					in the top row.
				</li>
				<li class="page-section-list-item">
					Users can select and deselect list items by using the corresponding checkbox.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-actions">
			<h3 id="subSectionTitleBehaviorsActions" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-actions')">
				Actions <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Lists can have a vertical ellipsis icon button on the far right of the row. This icon
					button opens up a menu of actions that can be performed on the list item.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-empty-state">
			<h3 id="subSectionTitleBehaviorsEmptyState" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-empty-state')">
				Empty state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A list is in an empty state when there is no data to display or an error occurs
					when loading the data.
				</li>
				<li class="page-section-list-item">
					While a list is in a loading state, it appears empty. A list that finishes loading
					and has no data displays a message notifying the user why the list is empty.
					This can include a button to fix the problem, an icon to indicate there was an error, or just a brief message.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-loading-state">
			<h3 id="subSectionTitleBehaviorsLoadingState" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-loading-state')">
				Loading state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When data is loading a repeating progress indicator line appears directly beneath the list header.
				</li>
				<li class="page-section-list-item">
					Until the loading is done, all functionality is disabled. Once loading finishes
					the functionality is restored and the progress indicator disappears.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-pagination">
			<h3 id="subSectionTitleBehaviorsPagination" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-pagination')">
				Pagination <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pagination is optional on lists. Pagination should responsively change size to
					match the dimensions of the list. Pagination is disabled until the list has
					finished loading.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					List items should have a label that clearly indicates what the user is selecting.
					Labels should be short, but descriptive. A secondary line of supporting text can be used below the label.
					An icon can also be used with the list item label to help enhance meaning.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-list-vs-table">
			<h3 id="subSectionTitleUsageListVsTable" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-list-vs-table')">
				List or data table <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Lists display a single column of data and are typically narrower than
					tables. Lists cannot be sorted or filtered like data tables, but they
					can support row selections and row actions.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-list-vs-menu">
			<h3 id="subSectionTitleUsageListVsMenu" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-list-vs-menu')">
				List or menu <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Lists display data. The list items can have row actions associated with
					them, but they are not actions in themselves. Use a menu when displaying
					a list of actions.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/checkbox">Checkbox component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/data-table">Data table component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/menu">Menu component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/pagination">Pagination component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/progress-indicator">Progress indicator</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-link">Text link</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'List Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
