export const typographyData = [
	{
		sectionName: 'Page title',
		styleName: 'Page title style',
		className: 'text-styles-h1',
		description: 'Page title text (H1)',
		sectionUrl: '',
	},
	{
		sectionName: 'Accordion component',
		styleName: 'Sub-section title style (H3)',
		className: 'text-styles-h3',
		description: 'Header title (H3)',
		sectionUrl: 'components/accordion',
	},
	{
		sectionName: 'Banner component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Message text',
		sectionUrl: '',
	},
	{
		sectionName: 'Banner component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: 'Button text',
		sectionUrl: '',
	},
	{
		sectionName: 'Bar chart component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Legend text, axis label',
		sectionUrl: 'components/bar-chart',
	},
	{
		sectionName: 'Breadcrumb component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Current page text',
		sectionUrl: 'components/breadcrumb',
	},
	{
		sectionName: 'Breadcrumb component',
		styleName: 'Body style (with underline)',
		className: 'text-styles-body-link',
		description: 'Text link text',
		sectionUrl: 'components/breadcrumb',
	},
	{
		sectionName: 'Button component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: 'Button text',
		sectionUrl: 'components/button',
	},
	{
		sectionName: 'Button group component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: 'Button text',
		sectionUrl: 'components/button-group',
	},
	{
		sectionName: 'Checkbox component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text',
		sectionUrl: 'components/checkbox',
	},
	{
		sectionName: 'Checkbox component',
		styleName: 'Supporting style',
		className: 'text-styles-supporting',
		description: 'Supporting content',
		sectionUrl: 'components/checkbox',
	},
	{
		sectionName: 'Combo box component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Text field label',
		sectionUrl: '',
	},
	{
		sectionName: 'Combo box component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text field value',
		sectionUrl: '',
	},
	{
		sectionName: 'Combo box component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Text field instructional text',
		sectionUrl: '',
	},
	{
		sectionName: 'Combo box component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Text field error text',
		sectionUrl: '',
	},
	{
		sectionName: 'Combo box component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'List item text',
		sectionUrl: '',
	},
	{
		sectionName: 'Data grid component',
		styleName: 'Body (bold) style',
		className: 'text-styles-body-bold',
		description: 'Grid header item count',
		sectionUrl: 'components/data-grid',
	},
	{
		sectionName: 'Data grid component',
		styleName: 'Sub-section title style (H4)',
		className: 'text-styles-h4',
		description: 'Grid header column name',
		sectionUrl: 'components/data-grid',
	},
	{
		sectionName: 'Data grid component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Grid cell text',
		sectionUrl: 'components/data-grid',
	},
	{
		sectionName: 'Data table component',
		styleName: 'Sub-section title style (H4)',
		className: 'text-styles-h4',
		description: 'Grid header column name',
		sectionUrl: 'components/data-table',
	},
	{
		sectionName: 'Data table component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Grid cell text',
		sectionUrl: 'components/data-table',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Text field label',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text field value',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Text field instructional text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Text field error text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Months other than "Jan" in month list text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Calendar day name text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Calendar number text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Body style (bold)',
		className: 'text-styles-body-bold',
		description: 'Calendar month name text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Body (bold) style',
		className: 'text-styles-body-bold',
		description: '"Jan" in month list text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: '"Current Month" primary text button text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Date picker component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: '"Today" secondary text button text',
		sectionUrl: 'components/date-picker',
	},
	{
		sectionName: 'Donut chart component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Legend text',
		sectionUrl: 'components/donut-chart',
	},
	{
		sectionName: 'Donut chart component',
		styleName: 'Supporting style',
		className: 'text-styles-supporting',
		description: 'Donut segment label',
		sectionUrl: 'components/donut-chart',
	},
	{
		sectionName: 'Drop down component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Text field label',
		sectionUrl: '',
	},
	{
		sectionName: 'Drop down component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text field value',
		sectionUrl: '',
	},
	{
		sectionName: 'Drop down component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Text field instructional text',
		sectionUrl: '',
	},
	{
		sectionName: 'Drop down component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Text field error text',
		sectionUrl: '',
	},
	{
		sectionName: 'Drop down component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'List item text',
		sectionUrl: '',
	},
	{
		sectionName: 'File upload component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Label text',
		sectionUrl: '',
	},
	{
		sectionName: 'File upload component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Center message text',
		sectionUrl: '',
	},
	{
		sectionName: 'File upload component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'File requirements text',
		sectionUrl: '',
	},
	{
		sectionName: 'File upload component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'File list text',
		sectionUrl: '',
	},
	{
		sectionName: 'File upload component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Error text',
		sectionUrl: '',
	},
	{
		sectionName: 'Help text component',
		styleName: 'Sub-section title style (H4)',
		className: 'text-styles-h4',
		description: 'Title text (H4)',
		sectionUrl: 'components/help-text',
	},
	{
		sectionName: 'Help text component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Message text',
		sectionUrl: 'components/help-text',
	},
	{
		sectionName: 'Label / value pair component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Emphasized label variation - label',
		sectionUrl: 'components/label-value',
	},
	{
		sectionName: 'Label / value pair component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Emphasized label variation - value',
		sectionUrl: 'components/label-value',
	},
	{
		sectionName: 'Label / value pair component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Emphasized value variation - label',
		sectionUrl: 'components/label-value',
	},
	{
		sectionName: 'Label / value pair component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Emphasized value variation - value',
		sectionUrl: 'components/label-value',
	},
	{
		sectionName: 'Line chart component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Legend text, axis label',
		sectionUrl: 'components/line-chart',
	},
	{
		sectionName: 'List component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Item text',
		sectionUrl: 'components/list',
	},
	{
		sectionName: 'List component',
		styleName: 'Supporting style',
		className: 'text-styles-supporting',
		description: 'Item supporting text',
		sectionUrl: 'components/list',
	},
	{
		sectionName: 'List component',
		styleName: 'Body (bold) style',
		className: 'text-styles-body-bold',
		description: 'Grid header item count',
		sectionUrl: 'components/list',
	},
	{
		sectionName: 'List component',
		styleName: 'Body style (with underline)',
		className: 'text-styles-body-link',
		description: 'Select all rows',
		sectionUrl: 'components/list',
	},
	{
		sectionName: 'Menu component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: 'Button trigger',
		sectionUrl: 'components/menu',
	},
	{
		sectionName: 'Menu component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Menu text',
		sectionUrl: 'components/menu',
	},
	{
		sectionName: 'Modal component',
		styleName: 'Section title style',
		className: 'text-styles-h2',
		description: 'Title text (H2)',
		sectionUrl: 'components/modal',
	},
	{
		sectionName: 'Modal component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Mesage text',
		sectionUrl: 'components/modal',
	},
	{
		sectionName: 'Modal component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: 'Button text',
		sectionUrl: 'components/modal',
	},
	{
		sectionName: 'Navigation component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Item text',
		sectionUrl: '',
	},
	{
		sectionName: 'Number field component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Text field label',
		sectionUrl: 'components/number-field',
	},
	{
		sectionName: 'Number field component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text field value',
		sectionUrl: '',
	},
	{
		sectionName: 'Number field component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Text field instructional text',
		sectionUrl: 'components/number-field',
	},
	{
		sectionName: 'Number field component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Text field error text',
		sectionUrl: 'components/number-field',
	},
	{
		sectionName: 'Pagination component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Drop-down text',
		sectionUrl: 'components/pagination',
	},
	{
		sectionName: 'Pagination component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text',
		sectionUrl: 'components/pagination',
	},
	{
		sectionName: 'Panel pattern',
		styleName: 'Section title style',
		className: 'text-styles-h2',
		description: 'Title text (H2)',
		sectionUrl: '',
	},
	{
		sectionName: 'Overlay panel pattern',
		styleName: 'Section title style',
		className: 'text-styles-h2',
		description: 'Title text (H2)',
		sectionUrl: '',
	},
	{
		sectionName: 'Password field component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Text field label',
		sectionUrl: 'components/password-field',
	},
	{
		sectionName: 'Password field component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text field value',
		sectionUrl: 'components/password-field',
	},
	{
		sectionName: 'Password field component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Text field instructional text',
		sectionUrl: 'components/password-field',
	},
	{
		sectionName: 'Password field component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Text field error text',
		sectionUrl: 'components/password-field',
	},
	{
		sectionName: 'Pie chart component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Legend text',
		sectionUrl: 'components/pie-chart',
	},
	{
		sectionName: 'Pie chart component',
		styleName: 'Supporting style',
		className: 'text-styles-supporting',
		description: 'Pie segment label',
		sectionUrl: 'components/pie-chart',
	},
	{
		sectionName: 'Pop-up component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Content text',
		sectionUrl: '',
	},
	{
		sectionName: 'Progress indicator component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Indeterminate circle label',
		sectionUrl: 'components/progress-indicator',
	},
	{
		sectionName: 'Progress indicator component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Transitional text',
		sectionUrl: 'components/progress-indicator',
	},
	{
		sectionName: 'Radio button component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Value',
		sectionUrl: 'components/radio-button',
	},
	{
		sectionName: 'Radio button component',
		styleName: 'Supporting style',
		className: 'text-styles-supporting',
		description: 'Secondary value',
		sectionUrl: 'components/radio-button',
	},
	{
		sectionName: 'Range slider component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Label text',
		sectionUrl: '',
	},
	{
		sectionName: 'Range slider component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Values',
		sectionUrl: '',
	},
	{
		sectionName: 'Severity component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Severity text',
		sectionUrl: 'components/severity',
	},
	{
		sectionName: 'Slider component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Label text',
		sectionUrl: '',
	},
	{
		sectionName: 'Slider component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Values',
		sectionUrl: '',
	},
	{
		sectionName: 'Slider component',
		styleName: 'Supporting text',
		className: 'text-styles-supporting',
		description: 'Instructional text',
		sectionUrl: '',
	},
	{
		sectionName: 'Switch component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Value',
		sectionUrl: 'components/switch',
	},
	{
		sectionName: 'Tab component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Item text',
		sectionUrl: 'components/tab',
	},
	{
		sectionName: 'Tag component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text',
		sectionUrl: 'components/tag',
	},
	{
		sectionName: 'Text field component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Label text',
		sectionUrl: 'components/text-field',
	},
	{
		sectionName: 'Text field component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text (input)',
		sectionUrl: 'components/text-field',
	},
	{
		sectionName: 'Text field component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Instructional text',
		sectionUrl: 'components/text-field',
	},
	{
		sectionName: 'Text field component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Error text',
		sectionUrl: 'components/text-field',
	},
	{
		sectionName: 'Text link component',
		styleName: 'Body style (with underline)',
		className: 'text-styles-body-link',
		description: 'Text',
		sectionUrl: 'components/text-link',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Text field label',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text field value',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Instructional style',
		className: 'text-styles-instructional',
		description: 'Text field instructional text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Error style',
		className: 'text-styles-error',
		description: 'Text field error text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Pop-up time list text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Body (bold) style',
		className: 'text-styles-body-bold',
		description: 'Pop-up time list column header text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Body (bold) style',
		className: 'text-styles-body-bold',
		description: 'Pop-up selected time text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: '"Now" primary text button text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: '"Set" primary button text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Time picker component',
		styleName: 'Button style',
		className: 'text-styles-button',
		description: '"Cancel" secondary button text',
		sectionUrl: 'components/time-picker',
	},
	{
		sectionName: 'Timeline component',
		styleName: 'Label style',
		className: 'text-styles-label',
		description: 'Label text',
		sectionUrl: '',
	},
	{
		sectionName: 'Timeline component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Message text',
		sectionUrl: '',
	},
	{
		sectionName: 'Timeline component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Timestamp text',
		sectionUrl: '',
	},
	{
		sectionName: 'Toast component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Message text',
		sectionUrl: 'components/toast',
	},
	{
		sectionName: 'Toast component',
		styleName: 'Body style (with underline)',
		className: 'text-styles-body-link',
		description: 'Text link text',
		sectionUrl: 'components/toast',
	},
	{
		sectionName: 'Tooltip component',
		styleName: 'Body style',
		className: 'text-styles-body',
		description: 'Text',
		sectionUrl: 'components/tooltip',
	},
];
