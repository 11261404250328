import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shLink, shXmark } from '@beyondtrust/shield-icons';

// Data Sources
import {
	bannerStylesDefaultTheme,
	bannerStylesDefaultTextButtonTheme,
	bannerStylesDefaultSecondaryTextButtonTheme,
	bannerStylesDefaultInformationalTheme, bannerStylesDefaultSuccessTheme, 
	bannerStylesDefaultWarningTheme, bannerStylesDefaultErrorTheme,
	bannerStylesDefaultAccordionTheme,
	bannerStylesDarkTheme,
	bannerStylesDarkTextButtonTheme, bannerStylesDarkSecondaryTextButtonTheme,
	bannerStylesDarkInformationalTheme, bannerStylesDarkSuccessTheme,
	bannerStylesDarkWarningTheme, bannerStylesDarkErrorTheme,
	bannerStylesDarkAccordionTheme,
	bannerStylesAccordionTypography,
	bannerStylesTypography,
	bannerStylesStructure,
	bannerStylesAccordionStructure
} from './banner-styles';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.less'],
})
export class BannerComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;

	// Imported Style Variables
	public stylesDefaultAll = bannerStylesDefaultTheme;
	public stylesDefaultTextButton = bannerStylesDefaultTextButtonTheme;
	public stylesDefaultSecondaryTextButton = bannerStylesDefaultSecondaryTextButtonTheme;
	public stylesDefaultInformational = bannerStylesDefaultInformationalTheme;
	public stylesDefaultSuccess = bannerStylesDefaultSuccessTheme;
	public stylesDefaultWarning = bannerStylesDefaultWarningTheme;
	public stylesDefaultError = bannerStylesDefaultErrorTheme;
	public stylesDefaultAccordion = bannerStylesDefaultAccordionTheme;
	public stylesDark = bannerStylesDarkTheme;
	public stylesDarkTextButton = bannerStylesDarkTextButtonTheme;
	public stylesDarkSecondaryTextButton = bannerStylesDarkSecondaryTextButtonTheme;
	public stylesDarkInformational = bannerStylesDarkInformationalTheme;
	public stylesDarkSuccess = bannerStylesDarkSuccessTheme;
	public stylesDarkWarning = bannerStylesDarkWarningTheme;
	public stylesDarkError = bannerStylesDarkErrorTheme;
	public stylesDarkAccordion = bannerStylesDarkAccordionTheme;
	public stylesTypography = bannerStylesTypography;
	public stylesAccordionTypography = bannerStylesAccordionTypography;
	public stylesStructure = bannerStylesStructure;
	public stylesAccordionStructure = bannerStylesAccordionStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: true,
			children: [],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Dismiss',
					value: 'dismiss',
				},
				{
					name: 'Other button',
					value: 'other-button',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Informational',
					value: 'informational',
				},
				{
					name: 'Warning',
					value: 'warning',
				},
				{
					name: 'Error',
					value: 'error',
				},
				{
					name: 'With accordion',
					value: 'with-accordion',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Placement',
					value: 'placement',
				},
				{
					name: 'Banners or toasts',
					value: 'banners-vs-toasts',
				},
			],
		},
		{
			name: 'Resources',
			value: 'resources',
			empty: false,
			children: [],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];
	
	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public bannerHTML = `Code Example Content (found at bottom of .ts file)`;
}
