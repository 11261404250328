import { menuStylesDarkTheme, menuStylesDefaultTheme } from './../menu/menu-styles';
import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink, shDotsVertical, shXmark, shEdit, shTrash } from '@beyondtrust/shield-icons';

// Data Sources
import {
	listStylesDefaultTheme,
	listStylesDarkTheme,
	listStylesTypography,
	listStylesStructure,
	listCheckboxDefaultTheme,
	listCheckboxDarkTheme,
} from './list-styles';

import {
	paginationStylesDefaultTheme,
	paginationStylesDarkTheme,
} from '../pagination/pagination-styles';

import { data, DataItem } from './data';
import { RowArgs, SelectAllCheckboxState, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { iconStylesDarkTheme, iconStylesDefaultTheme } from '../icon/icon-styles';

@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.less'],
})
export class ListComponent {
	public currLive = 'single';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	public xIcon = shTrash;
	public editIcon = shEdit;
	public dotsIcon = shDotsVertical;
	public showPagination = true;
	public showInstructionalText = true;

	// Imported Style Variables
	public stylesDefault = listStylesDefaultTheme;
	public stylesDark = listStylesDarkTheme;
	public stylesDefaultCheckbox = listCheckboxDefaultTheme;
	public stylesDarkCheckbox = listCheckboxDarkTheme;
	public stylesDefaultPagination = paginationStylesDefaultTheme;
	public stylesDarkPagination = paginationStylesDarkTheme;
	public stylesDefaultMenu = menuStylesDefaultTheme;
	public stylesDarkMenu = menuStylesDarkTheme;
	public stylesDefaultIcon = iconStylesDefaultTheme;
	public stylesDarkIcon = iconStylesDarkTheme;
	public stylesTypography = listStylesTypography;
	public stylesStructure = listStylesStructure;
	public data = data;
	public selectedRows: unknown[] = [];
	public selectAllState: SelectAllCheckboxState = 'unchecked';
	public singleSelectableSettings: SelectableSettings = {
		checkboxOnly: true,
		mode: 'single'
	};
	public multiSelectableSettings: SelectableSettings = {
		checkboxOnly: true,
		mode: 'multiple'
	};

	public selectedRowIdentity: string | null = null;
	// MultiSelectItems holds all of them in an object to find them in an easier manner.
	// Compared to an array, this will perform well because in array, we would need to iterate a lot but with this
	// we can easily find, delete and interact.
    public multiSelectItems: { [key: string | number]: boolean } = {};
    public allSelected: boolean;
    public checkedLength: number = 0;


	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: "Code examples",
					value: "code"
				},
				{
					name: "API",
					value: "api"
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Select',
					value: 'select',
				},
				{
					name: 'Actions',
					value: 'actions',
				},
				{
					name: 'Empty state',
					value: 'empty-state',
				},
				{
					name: 'Loading state',
					value: 'loading-state',
				},
				{
					name: 'Pagination',
					value: 'pagination',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'List or data table',
					value: 'list-vs-table',
				},
				{
					name: 'List or menu',
					value: 'list-vs-menu',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public isRowSelected = (row: RowArgs): boolean => !!this.multiSelectItems[row.dataItem.name];
    public iscurrentRowSelected = (row: RowArgs): boolean => this.selectedRowIdentity === row.dataItem.name;

	public disableMenus = false;

    public onCheckboxChange(dataItem: DataItem): void {
        if (this.selectedRowIdentity === dataItem.email) {
            this.selectedRowIdentity = null;
        } else {
            this.selectedRowIdentity = dataItem.email;
        }
    }

    public rowSelectedClass = (context: { index:number , dataItem: DataItem}): string => {
        return context.dataItem.email === this.selectedRowIdentity ? 'k-selected': '';
    }

	public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
		this.selectAllState = checkedState;
        this.multiSelectItems = {};
        this.checkedLength = 0;
		this.disableMenus = false;
        if (checkedState === 'checked') {
			this.disableMenus = true;
            this.checkedLength = this.data.length;
            this.data.forEach(dataItem => {
                this.selectionChange(dataItem, false);
            })
        };
	}

	public selectionChange(dataItem: DataItem, updateAllState: boolean = true): void {
        if (this.multiSelectItems[dataItem.name]) {
            delete this.multiSelectItems[dataItem.name];
        } else {
            this.multiSelectItems[dataItem.name] = true;
        }

        if (updateAllState) {
            this.checkedLength = Object.keys(this.multiSelectItems).length;
			this.disableMenus = this.checkedLength > 1;
            if (this.checkedLength === this.data.length) {
                this.selectAllState = 'checked';
            } else if (this.checkedLength > 0) {
                this.selectAllState = 'indeterminate';
            } else {
                this.selectAllState = 'unchecked';
            }
        }
    }

	public onMenuOpen(item: DataItem, gridType: 'single' | 'multi') {
		if (gridType === 'single'){
			if (this.selectedRowIdentity !== item.email) {
				this.selectedRowIdentity = item.email
			}
		} else {
			if(!this.multiSelectItems[item.name] && Object.keys(this.multiSelectItems).length <= 1) {
				this.multiSelectItems = {[item.name]: true};
				this.checkedLength = 1;
				this.selectAllState = 'indeterminate';
			}
		}
	}

	public singleSelectListHTML = `
<kendo-grid
	[class.page-live-show]="currLive === 'single'"
	[kendoGridBinding]="data"
	[sortable]="false"
	[filterable]="false"
	[pageable]="showPagination && {type: 'input', pageSizes: [10, 25, 50, 100, 200]}"
	[pageSize]="showPagination ? 25 : null"
	[height]="500"
	[resizable]="false"
	[selectable]="singleSelectableSettings"
	[rowSelected]="iscurrentRowSelected"
	[rowClass]="rowSelectedClass">
	<kendo-grid-checkbox-column class="sh-list-checkbox-column">
		<ng-template kendoGridHeaderTemplate let-column>
			<div class="sh-item-count-container">
			<strong class="sh-item-count">{{ data.length }} items</strong>
			</div>
		</ng-template>
		<ng-template kendoGridCellTemplate let-dataItem>
			<div class="k-checkbox-wrap">
			<input
			#checkboxList
			type="checkbox"
			kendoCheckBox
			[checked]="dataItem.email === selectedRowIdentity"
			(change)="onCheckboxChange(dataItem)"
			[attr.aria-label]="dataItem.name + ' ' + dataItem.email"
			/>
			<kendo-label [for]="checkboxList" [text]="dataItem.name"></kendo-label>
			</div>
			<kendo-formhint *ngIf="showInstructionalText">{{dataItem.email}}</kendo-formhint>
		</ng-template>
	</kendo-grid-checkbox-column>
	<kendo-grid-column [width]="54">
		<ng-template kendoGridCellTemplate let-dataItem>
			<kendo-menu [openOnClick]="{toggle: 'click'}">
				<ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
					<kendo-button class="sh-list-action-button" themeColor="primary" fillMode="flat" [kendoMenuItemLink]="index" [svgIcon]="item.svgIcon">
						{{ item.text }}
					</kendo-button>
				</ng-template>
				<kendo-menu-item [svgIcon]="dotsIcon">
					<kendo-menu-item text="Edit User" [svgIcon]="editIcon"></kendo-menu-item>
					<kendo-menu-item text="Delete" [svgIcon]="xIcon"></kendo-menu-item>
				</kendo-menu-item>
			</kendo-menu>
		</ng-template>
	</kendo-grid-column>
</kendo-grid>`;

    public multiSelectListHTML = `
<kendo-grid
	[class.page-live-show]="currLive === 'multi'"
	[kendoGridBinding]="data"
	[sortable]="false"
	[filterable]="false"
	[pageable]="showPagination && {type: 'input', pageSizes: [10, 25, 50, 100, 200]}"
	[pageSize]="showPagination ? 25 : null"
	[height]="500"
	[resizable]="false"
	[selectable]="multiSelectableSettings"
	[rowSelected]="isRowSelected">
	<kendo-grid-checkbox-column class="sh-list-checkbox-column">
		<ng-template kendoGridHeaderTemplate let-column>
			<input type="checkbox"
				kendoCheckBox
				kendoGridSelectAllCheckbox
				[state]="selectAllState"
				(selectAllChange)="onSelectAllChange($event)"/>
			<div class="sh-item-count-container">
				<strong class="sh-item-count">{{ data.length }} items</strong>
				({{ checkedLength }} selected |
				<a class="sh-item-count-selection"
					tabindex="0"
					aria-disabled="false"
					aria-haspopup="false"
					type="button"
					(click)="onSelectAllChange(selectAllState === 'checked' ? 'unchecked' : 'checked')">
					{{  selectAllState === 'checked' ? 'Deselect All' : 'Select all ' + data.length + ' rows' }}
				</a>)
			</div>
		</ng-template>
		<ng-template kendoGridCellTemplate let-dataItem>
		<div class="k-checkbox-wrap">
			<input
			#checkboxList
			type="checkbox"
			kendoCheckBox
			(change)="selectionChange(dataItem)"
			[checked]="this.multiSelectItems[dataItem.name]"
			[attr.aria-label]="dataItem.name + ' ' + dataItem.email"
			/>
			<kendo-label [for]="checkboxList" [text]="dataItem.name"></kendo-label>
		</div>
		<kendo-formhint *ngIf="showInstructionalText">{{dataItem.email}}</kendo-formhint>
		</ng-template>
	</kendo-grid-checkbox-column>
	<kendo-grid-column [width]="48">
		<ng-template kendoGridCellTemplate let-dataItem>
			<kendo-menu [openOnClick]="{toggle: 'click'}">
				<ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index" >
					<kendo-button class="sh-list-action-button" themeColor="primary" fillMode="flat" [kendoMenuItemLink]="index" [svgIcon]="item.svgIcon">
						{{ item.text }}
					</kendo-button>
				</ng-template>
				<kendo-menu-item [svgIcon]="dotsIcon" [disabled]="disableMenus">
					<kendo-menu-item text="Edit User" [svgIcon]="editIcon"></kendo-menu-item>
					<kendo-menu-item text="Delete" [svgIcon]="xIcon"></kendo-menu-item>
				</kendo-menu-item>
			</kendo-menu>
		</ng-template>
	</kendo-grid-column>
</kendo-grid>`;
}
