<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Banner'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">First sample live example</option>
					<option value="second">Second sample live example</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'default'">
					First live example content
				</div>
				<div class="page-live-example" [class.page-live-show]="currLive === 'second'">
					Second live example content
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title page-section-title-empty" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-section-content">
			There are no developer resources for this component.
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultAll" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">All variations - text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultTextButton" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">All variations - secondary text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultSecondaryTextButton" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Informational</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultInformational" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Success</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultSuccess" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Warning</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultWarning" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Error</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultError" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Accordion</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultAccordion" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">All variations - text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkTextButton" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">All variations - secondary text button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkSecondaryTextButton" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Informational</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkInformational" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Success</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkSuccess" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Warning</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkWarning" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Error</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkError" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Accordion</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkAccordion" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Accordion</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesAccordionTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Accordion</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesAccordionStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-dismiss">
			<h3 id="subSectionTitleBehaviorsDismiss" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-dismiss')">
				Dismiss <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners contain a dismiss button that removes the banner for the time being. Dismissing a banner might
					mean the underlying trigger for it still applies, so it will appear on the user’s next visit to the page.
					The button is intended to allow the user to dismiss the banner temporarily and continue their work.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-other-button">
			<h3 id="subSectionTitleBehaviorsOtherButton" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-other-button')">
				Other button <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners can include an optional secondary button. This button is to the right of <b>Dismiss</b>. This button
					can perform an action or redirect the user to another part of the application, such as <b>Go to account</b>
					or <b>View details.</b>
				</li>
				<li class="page-section-list-item">
					If a banner message is more than one line or has an accordion, the buttons are pushed to the bottom right.
					The buttons only appear on the same line as the message if the message is a single line.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-informational">
			<h3 id="subSectionTitleVariationsInformational" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-informational')">
				Informational <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Informational banners tell a user about an event.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-warning">
			<h3 id="subSectionTitleVariationsWarning" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-warning')">
				Warning <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Warning banners draw attention to an event the user might want to address now or monitor.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-error">
			<h3 id="subSectionTitleVariationsError" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-error')">
				Error <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Error banners let a user know something has failed and needs their attention.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-with-accordion">
			<h3 id="subSectionTitleVariationsWithAccordion" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-with-accordion')">
				With accordion <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners (of any type) with accordions allow for more detail in a message, such as data or affected accounts.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners fill the width of their container and expand height-wise to match the content.
					This includes when an accordion is expanded. Banners have an indicator bar that is 4px tall.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do use banners:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							For the most important system information
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don’t use banners:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							To inform users of a process that the app is performing or about to perform
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners, similar to toasts, have an icon to the left of the message that matches
					the banner’s variation. The icon and top indicator bar share the same color.
				</li>
				<li class="page-section-list-item">
					Since banners are meant to catch a user’s attention and interrupt them, the content
					should be focused, succinct, and purposeful. Get to the point quickly and stick to a
					single theme or message to avoid overwhelming users with too much information.
				</li>
				<li class="page-section-list-item">
					Banners span the full width of their container, but the message length shouldn’t be
					longer than 60 characters per line. This follows the <a href="guidelines/typography">typography guidance</a>.
				</li>
				<li class="page-section-list-item">
					The first line of the banner message should include the most important information.
					The second line should add context and description. Each line shouldn’t be longer
					than a sentence each.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								You have 2 incomplete connectors.
							</li>
							<li class="comparison-body-text-list-item">
								Enter the missing credentials to finish setup.
							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								We’ve noticed you have 2 partially completed connectors. Please enter the
								remaining credentials to complete the configuration.
							</li>
						</ul>
					</div>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If more information is needed, use an accordion or include a text button that leads to
					another page. Accordion content should be additional context or details, not instructions
					that are necessary to a user’s success.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Message: Password check couldn’t be completed. Check your connection and try again.
							</li>
							<li class="comparison-body-text-list-item">
								Accordion content: 324 password tests failed.
							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Message: Password check couldn’t be completed. 324 password tests failed.
							</li>
							<li class="comparison-body-text-list-item">
								Accordion content: Please check your connection and try again.
							</li>
						</ul>
					</div>
				</li>
			</ul>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If a banner includes a button, the button text should be clear where the button will take a user.
					If any action is needed, use a strong verb that makes it clear what needs to be done. Do not use
					“Learn more.” “Learn more” is ambiguous and creates uncertainty. It also causes accessibility
					concerns when used alone.
				</li>
			</ul>
			<ul class="page-section-comparison-list">
				<li class="page-section-comparison-list-item comparison-do">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/Status/checkmark</title>
							<g id="Icon/Status/checkmark" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M20.6534688,4.16908529 C20.8789629,3.94363824 21.2445273,3.94363824 21.4700213,4.16908529 L22.8308795,5.52999044 C23.0563735,5.75548448 23.0563735,6.12109588 22.8308795,6.34654293 L8.40527544,20.772194 C8.17973441,20.997641 7.81417,20.997641 7.58872295,20.772194 L1.16912053,14.3525915 C0.94362649,14.1270505 0.94362649,13.7614861 1.16912053,13.5359921 L2.5299787,12.1751339 C2.75551972,11.9496398 3.12108413,11.9496398 3.34657817,12.1751339 L7.99699919,16.8255549 L20.6534688,4.16908529 Z"
									id="" fill="#000000" />
							</g>
						</svg>
						Try This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Visit help center
							</li>
						</ul>
					</div>
				</li>
				<li class="page-section-comparison-list-item comparison-do-not">
					<div class="page-section-comparison-header">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
							height="20px" viewBox="0 0 24 24" version="1.1">
							<title>Icon/NavObjects/close</title>
							<g id="Icon/NavObjects/close" stroke="none" stroke-width="1" fill="none"
								fill-rule="evenodd">
								<path
									d="M21.4999535,10.2499803 C22.3282291,10.2499803 22.9999499,10.9217011 22.9999499,11.7499768 L22.9999499,13.2499732 C22.9999499,14.0782488 22.3282291,14.7499696 21.4999535,14.7499696 L14.7499696,14.7499696 L14.7499696,21.4999535 C14.7499696,22.3282291 14.0782488,22.9999499 13.2499732,22.9999499 L11.7499768,22.9999499 C10.9217011,22.9999499 10.2499803,22.3282291 10.2499803,21.4999535 L10.2499803,14.7499696 L3.5,14.7499696 C2.67172081,14.7499696 2,14.0782488 2,13.2499732 L2,11.7499768 C2,10.9217011 2.67172081,10.2499803 3.5,10.2499803 L10.2499803,10.2499803 L10.2499803,3.5 C10.2499803,2.67172081 10.9217011,2 11.7499768,2 L13.2499732,2 C14.0782488,2 14.7499696,2.67172081 14.7499696,3.5 L14.7499696,10.2499803 L21.4999535,10.2499803 Z"
									id="add2" fill="#000000"
									transform="translate(12.499975, 12.499975) rotate(-315.000000) translate(-12.499975, -12.499975) " />
							</g>
						</svg>
						Not This
					</div>
					<div class="page-section-comparison-body">
						<ul class="comparison-body-text-list">
							<li class="comparison-body-text-list-item">
								Learn more
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-placement">
			<h3 id="subSectionTitleUsagePlacement" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-placement')">
				Placement <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners appear at the top of their parent container — below the container’s title, but above any other information.
				</li>
				<li class="page-section-list-item">
					Multiple banners can be on a page. They appear vertically stacked. Ideally, only one banner would show at a
					time. Sometimes the system will trigger more than one, but we should design with one-at-a-time in mind to
					avoid overwhelming users.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-banners-vs-toasts">
			<h3 id="subSectionTitleUsageBannersVsToasts" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-banners-vs-toasts')">
				Banners or toasts <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Banners display messages that are often a summary or notifications generated by the
					system on a page load or refresh. Toasts are immediate reactions to a user action.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="resources">
		<h2 id="sectionTitleResources" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('resources')">
			Resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item resource-list-item">
				<a href="https://www.nngroup.com/articles/learn-more-links/" target="_blank">"Learn More" Links: You Can Do Better</a>
				<span class="resource-source">Katie Sherwin, Nielsen Norman Group</span>
			</li>
		</ul>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/accordion">Accordion component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/toast">Toast component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Banner Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
